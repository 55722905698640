import React, { useState } from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";

import '../styles/commissions.css';
import { OutboundLink } from "gatsby-plugin-google-gtag";
import { MDXRenderer } from "gatsby-plugin-mdx";
import ExpandableText from "../components/ExpandableText";

const getGoogleDrivePreviewURL = (url) => {
    // https://drive.google.com/file/d/1de0AnOPQO7XldNwhGpTbOgMA6bALCN4-/view?usp=sharing
    let { host, pathname } = new URL(url);
    if (host === "drive.google.com" || (["drive.google.com", "www.drive.google.com"].includes(host))) {
        const pathnameParts = pathname.split('/');
        const id = pathnameParts[pathnameParts.length-2]
        const output = `https://drive.google.com/file/d/${id}/preview`;
        return output;
    }
}

const CommissionPage = ({ data }) => {
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);
    
    return (
        <Layout>
            <SEO title="Independent Redistricting Commissions" />
            <h2>Independent Redistricting Commissions</h2>
            <div className="split-pane-container">
                <section>
                {
                    data &&
                    data.allAirtable.group.map((categoryGroup) => {
                        return <div>
                            <h2
                            className={`commission-resource-card ${categoryGroup === selectedCategory ? 'selected' : ''}`}>
                                {categoryGroup.fieldValue}
                            </h2>
                            <ExpandableText collapsedDefault={true}>
                            {
                                categoryGroup.nodes.map((commissionResource) => {
                                    return <div
                                        className={`commission-resource-card ${commissionResource === selectedItem ? 'selected' : ''}`}
                                        onClick={() => {
                                            setSelectedItem(commissionResource);
                                            setSelectedCategory(categoryGroup);
                                        }}>
                                        <h3>
                                        <OutboundLink
                                            target="_blank" rel="noopener noreferrer"
                                            href={commissionResource.data['CommissionResourceURL']}>
                                            {commissionResource.data['CommissionResourceTitle']} ➾ 
                                        </OutboundLink>
                                        </h3>
                                        {
                                            commissionResource.data['CommissionResourceDescription'] &&
                                            <MDXRenderer>
                                                {commissionResource.data['CommissionResourceDescription'].childMdx.body}
                                            </MDXRenderer>
                                        }
                                        {/* <div>
                                            <span className="keywords">
                                                {commissionResource.data['CommissionCategories']}
                                            </span>
                                        </div>  */}
                                    </div>
                                })
                            }
                            </ExpandableText>
                        </div>
                    })
                }
                </section>
                <section>
                    {
                        selectedItem && 
                        <h3>{selectedItem.data['CommissionResourceTitle']}</h3>
                    }
                    {
                        selectedItem &&
                        <iframe src={getGoogleDrivePreviewURL(selectedItem.data['CommissionResourceURL'])}></iframe>
                    }
                    {
                        !selectedItem &&
                        <div className="empty-pane">
                            <h2>Select an item from the list to view</h2>
                        </div>
                    }
                </section>
            </div>
        </Layout>
    )
}

export default CommissionPage;

export const commissionPageQuery = graphql`
query commissionPageQuery {
    allAirtable(filter: {table: {eq: "Commission"}}) {
      group(field: data___CommissionCategories) {
        nodes {
          id
          data {
            Last_Updated
            CommissionResourceTitle
            CommissionCategories
            CommissionResourceURL
            CommissionResourceDescription {
              id
              childMdx {
                body
              }
            }
            CommissionResourceKeywords
          }
        }
        fieldValue
      }
    }
  }
`;
