import React, {
  // useLayoutEffect,
  useRef,
  useState
} from "react";

import '../styles/expandableText.css';

const ExpandableText = (props) => {
  const expandableRef = useRef();
  // const [dimensions, setDimensions] = useState({});
  // useLayoutEffect(() => {
  //   setDimensions(expandableRef.current.getBoundingClientRect())
  // }, [expandableRef.current]);
  const buttonTitle = props.title ? props.title : `Section`;
  const [isExpanded, setIsExpanded] = useState(!!props.collapsedDefault ? false : true);
  return (
      <>
        <div className="ExpandableText-Header">
          {props.headerComponent}
          <button className={``}
                  onClick={() => setIsExpanded(!isExpanded)}>
            {!isExpanded ? `Show ${buttonTitle}` : `Hide ${buttonTitle}`}
          </button>
        </div>
        <div
          ref={expandableRef}
          className={isExpanded ? `ExpandableText`: `ExpandableText Collapsed`}>
          {props.children}
        </div>
      </>
  );
}

export default ExpandableText;